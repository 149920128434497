
import styled from "styled-components";


export const ShopingWrapper = styled.div`

.main_div{
    &:hover{
        .image_section img{
            width:155px;
            height:145px;
        }
    }
}


`