import styled from "styled-components";

export const SSLMain=styled.div`
margin-top: 7.7rem;

@media only screen and (max-width: 768px) {
    margin-top: 0;
    .ssl-slick{
margin-top: 2rem;
}
}


`
export const SSLImg=styled.img`
height: 5.6rem;

`
export const SSLImg1=styled.img`
height: 7.7rem;
`