import React from 'react'
import Homepage from '../../../components/LandingPageComponents/Homepage/Homepage'

const LandingPage = () => {
  return (
    <div>
        <Homepage/>
    </div>
  )
}

export default LandingPage