import styled from "styled-components";

export const FashionDiscountMainContainer=styled.div`
margin-top: 4.8rem;
`
export const FashionDiscountImgContainer=styled.div`
padding: 1.5rem;
`
export const FashionDiscountImg=styled.img`
height:18rem
`