export const surnames = [
    "Mr.",
    "Mrs.",
    "Miss.",
    "Ms.",
    "SR.",
    "Dr.",
    "Dra.",
    "Esq",
    "Prof.",
    "Don.",
    "Dona",
    "Chica",
    "Chico",
    "Sr.Don",
    "Adv.",
    "Sol.",
    "B.L.",
    "Rph",
    "Sci",
    "Bot.",
    "Ad",
    "Mkt",

]


