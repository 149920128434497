import React from 'react'
import Homepage2 from '../../../components/LandingPageComponents/Homepage2/Homepage2'
const MainPage = () => {
    return (
        <div>
           <Homepage2/>
        </div>
    )
}

export default MainPage