import React from 'react'


const Prescribe = () => {
  return (
    <>
      <h2 className='text-center font-bold text-[2rem] p-[3rem]'> Under Construction </h2>
    </>
  )
}

export default Prescribe